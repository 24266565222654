<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card">
        <div class="card-header">
            <h5>
                ارسال رسائل للحجاج
            </h5>
        </div>
        <div class="card-body">
            <div class="row g">
                <div class="col-6">
                    <button class="btn btn-success btn-block" v-if="hamla == '1'">
                        <i class="fa fa-check"></i>
                        الباقة 2
                    </button>
                    <button class="btn btn-outline-secondary btn-block" @click="hamla = '1'" v-if="hamla != '1'">
                        الباقة 2
                    </button>
                </div>
                <div class="col-6">
                    <button class="btn btn-success btn-block" v-if="hamla == '2'">
                        <i class="fa fa-check"></i>
                        الباقة 4
                    </button>
                    <button class="btn btn-outline-secondary btn-block" @click="hamla = '2'" v-if="hamla != '2'">
                        الباقة 4
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body" v-if="hamla">
            <div class="form-group">
              <span>الرسالة</span>
              <textarea class="form-control" rows="3" v-model="message"></textarea>
            </div>
            <div class="form-group">
              <label for="">رابط لصورة او ملف</label>
              <input type="url"
                class="form-control" style="text-align: left" v-model="file" placeholder="URL...">
            </div>
            <div class="card card-body">
                المتغيرات:
                <br><br>
                <ul>
                    <li>
                        {name} => الاسم
                    </li>
                    <li>
                        {bus} => الحافلة
                    </li>
                    <li>
                        {camp} => السكن
                    </li>
                    <li>
                        {number} => رقم الهوية
                    </li>
                    <li>
                        {order_id} => رقم الحجز
                    </li>
                    <li>
                        {password} => كلمة المرور
                    </li>
                    <li>
                        {city} => المدينة
                    </li>
                    <li>
                        {start_location} => نقطة الانطلاق
                    </li>
                    <li>
                        {changephoneurl} => رابط لتغيير رقم الجوال
                    </li>
                </ul>
            </div>
            <div class="card-footer">
                <div class="form-group col-12 col-lg-6 c">
                  <h5 for="">ارسال الى</h5>
                  <select class="form-control" v-model="type">
                    <option value="all">الكل</option>
                    <option value="not_attend_1">
                        الغير محضر في سكن مكة/منى
                    </option>
                    <option value="not_attend_2">
                        الغير محضر في سكن عرفات
                    </option>
                    <option value="not_join_app">
                        من لم يدخل التطبيق
                    </option>
                    <option value="bus">
                        ارسال الى حجاج حافلة
                    </option>
                  </select>
                </div>
                <div class="form-group col-12 col-lg-6 c" v-if="type == 'bus'">
                    <br>
                  <h5 for="">الحافلة</h5>
                  <select class="form-control" v-model="bus_id">
                    <option value="">-- اختر الحافلة --</option>
                    <option v-for="b in busses" :value="b._id" :key="b._id">
                        {{ b.title }}
                    </option>
                  </select>
                </div>
                <hr>
                <div class="col-12 text-center">
                    <button class="btn btn-success" @click="send('whatsapp')">
                        <i class="fa fa-whatsapp"></i>
                        ارسال بالواتس الذكي
                    </button>
                    &nbsp;
                    <button class="btn btn-primary" @click="send('fcm')">
                        <i class="fa fa-bell"></i>
                        ارسال اشعار FCM
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            message: "",
            user: JSON.parse(localStorage.getItem("user")),
            busses: [],
            camps: [],
            type: "all",
            hamla: false,
            bus_id: "",
            file: ""
        }
    },
    created(){
        if(!checkPer('users')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        $.post(api + '/admin/camps/list', {
            jwt: g.user.jwt
        }).then(function(r){
            g.camps = r.response;
        })
        $.post(api + '/admin/busses/list', {
            jwt: g.user.jwt
        }).then(function(r){
            g.busses = r.response;
        })
    },
    methods: {
        send(method){
            var g = this;
            $.post(api + '/admin/users/send', {
                jwt: g.user.jwt,
                message: g.message,
                type: g.type,
                hamla: g.hamla,
                method: method,
                bus_id: g.bus_id,
                file: g.file
            }).then(function(r){
                alert(r.response, 100)
            })
        },
    }
}
</script>

<style>

</style>